<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" title="设备选择" top="8vh">
            <el-container>
                <CommonTree
                    ref="commonTreeCore"
                    treeTitle="设备分类"
                    :treeData="treeData"
                    nodeKey="id"
                    searchTitle="typeName"
                    :defaultProps="{ label: 'typeName' }"
                    @getNodeClick="handleNodeClick"
                />
                <el-main>
                    <head-layout head-title="设备清单"/>
                    <grid-head-layout
                        ref="gridHeadLayout"
                        :search-columns="searchColumns"
                        @grid-head-search="gridHeadSearch"
                        @grid-head-empty="gridHeadEmpty"
                    ></grid-head-layout>
                    <grid-layout
                        ref="gridLayOut"
                        :grid-row-btn="gridRowBtn"
                        :table-options="tableOptions"
                        :table-data="tableData"
                        :table-loading="tableLoading"
                        :data-total="page.total"
                        :page="page"
                        @page-current-change="handleCurrentChange"
                        @page-size-change="handleSizeChange"
                        @page-refresh-change="onLoad"
                        @row-change="rowChange"
                    >
                        <template #equipmentCode="{row}">
                            <el-link type="primary" @click="rowView(row)">{{ row.equipmentCode }}</el-link>
                        </template>
                        <template #eqStatus="{row}">
                            <div class="eq-status-box">
                            <span class="eq-status" :class="`eq-status-${row.eqStatus}`">{{ getDicValue(statusList,row.eqStatus) }}</span>
                            </div>
                        </template>
                    </grid-layout>
                </el-main>
            </el-container>
        </el-dialog>
    </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import * as API from "@/api/equipmentFacilities/equipmentLedger";
import { mapGetters } from "vuex";
import {getDictionaryCode} from "@/api/system/dictbiz";

export default {
    // props: {
    //     multiple: {
    //         type: Boolean,
    //         default: false
    //     }
    // },
    components: {
        CommonTree,
        GridLayout,
        HeadLayout,
    },

    data () {
        return {
            dialogVisible: false,
            treeData: [],
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            tableData: [],
            tableLoading: false,
            statusList: [],
        }
    },

    computed: {
        ...mapGetters(["userInfo"]),
        tableOptions() {
            return {
                // customRowAddBtn: false,
                // menuWidth: 200,
                // menuFixed: "right",
                selection: false,
                linklabel: "equipmentCode",
                column: [
                {
                    label: "设备编号",
                    prop: "equipmentCode",
                    align: "center",
                    overHidden: true,
                    slot: true,
                },
                {
                    label: "设备名称",
                    prop: "equipmentName",
                    align: "left",
                    overHidden: true,
                },
                {
                    label: "规格型号",
                    prop: "spc",
                    align: "center",
                    overHidden: true,
                },
                {
                    label: "位置",
                    prop: "location",
                    align: "center",
                    overHidden: true,
                },
                {   
                    label: "状态",
                    prop: "eqStatus",
                    align: "center",
                    overHidden: true,
                    slot: true,
                    // dicUrl:"/api/sinoma-system/dict-biz/dictionary?code=eq_status",
                    // props: {
                    //     label: "dictValue",
                    //     value: "dictKey",
                    // },
                },
                ],
            };
        },

        gridRowBtn () {
            return [
                {
                    label: '选择',
                    emit: "row-change",
                    type: "text",
                    icon: ""
                }
            ]
        } 
    },

    methods: {
        init (data) {
            this.getDictionaryCode()
            this.dialogVisible = true
            let orgId = data ? data.orgId: ''
            this.getTreeData(orgId)
        },
        getTreeData(orgId) {
            API.eqLedgeClassTree({orgId: orgId || this.userInfo.dept_id}).then((res) => {
                this.treeData = res.data.data;
                if (this.treeData.length) {
                this.$nextTick(() => {
                    this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
                    this.treeData[0].id
                    );
                    this.handleNodeClick(this.treeData[0]);
                });
                }
            });
        },
        handleNodeClick(data, node) {
            this.nodeData = data;
            this.queryTypeId = data.id;
            // this.excelOption.column[0].data = { classHeadId: data.id };
            this.page.currentPage = 1;
            this.onLoad(this.page);
        },

        // 搜索
        gridHeadSearch(searchForm) {
            this.page.currentPage = 1;
            this.onLoad(this.page, searchForm);
        },
        // 清空
        gridHeadEmpty(searchForm) {
            this.page.currentPage = 1;
            this.onLoad(this.page);
        },
        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage;
            this.onLoad(this.page);
        },
        //size改变
        handleSizeChange(e) {
            this.page.currentPage = 1;
            this.page.pageSize = e.pageSize;
            this.onLoad(this.page);
        },

        // 获取列表数据
        onLoad(page, params = {}) {
            this.page = page;
            this.tableLoading = true;
            API.eqLedgerInventoryPage({
                current: page.currentPage,
                size: page.pageSize,
                orgId: this.nodeData.orgId,
                typeId: this.queryTypeId,
                ...params,
            }).then((res) => {
                if (res.data.code == 200) {
                this.tableLoading = false;
                this.tableData = res.data.data.records;
                this.page.total = res.data.data.total;
                this.$refs.gridLayOut.page.total = res.data.data.total;
                }
            });
        },

        rowChange (row) {
            this.dialogVisible = false
            this.$emit('callback',row)
        },

        rowView(row) {
            this.$router.push({
                path: `/equipmentFacilities/equipmentLedger/edit`,
                query: {
                type: "view",
                typeId: this.queryTypeId,
                id: row.id,
                code: row.equipmentCode,
                name: row.equipmentName,
                },
            });
        },
        getDictionaryCode() {
            getDictionaryCode("eq_status").then((res) => {
                this.statusList = res.data.data;
            });
        },

        getDicValue (dic, key) {
            let data = dic.find(val => val.dictKey == key)
            return data ? data.dictValue : ''
        },
    }

}
</script>

<style lang="scss" scoped>
::v-deep .el-tree {
  // height: calc(100vh - 220px) !important; 
  height: 60vh !important;
  max-height: 60vh !important;
  overflow: scroll;
}

::v-deep .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}

.eq-status-box{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.eq-status{
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
}
.eq-status-1{ // 启用
  border: 1px solid #67c23a;
  color: #67c23a;
  background-color: #EEF8E8;
}
.eq-status-2{ // 停用
  border: 1px solid #606266;
  color: #606266;
  background-color: #F2F2F3;
}
.eq-status-3{ // 维修中
  border: 1px solid #E49D3F;
  color: #E49D3F;
  background-color: #FDF5E9;
}
.eq-status-4{ // 报废
  border: 1px solid #F69090;
  color: #F69090;
  background-color: #FEEEEE;
}
</style>